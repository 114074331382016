.logo_header_sante img{
  width: 180px;
}
.style_flex_containe{
  display: flex;
}
.logo_header_sante {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.title_page_sante {
  padding-top: 15px;
  padding-bottom: 0px;
}
.title_page_sante h1{
  font-weight: 700;
  font-size: 30px;
}
h1{
  font-family: Raleway;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #666666;
}
.h4_font{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #666666;
  margin-left: 20px;
  margin-bottom: 0;
}
.accordion-item{
  margin-bottom: 20px;
  border-bottom: 1px solid #A1A1A1;
  border-top: none;
  border-left: none;
  border-radius: none;
}
.mbgray{
  border-bottom: 1px solid #A1A1A1;
}
.acordeon.accordion-item {
  background: #F0F0F0;
}
.accordion-body{
  padding: inherit!important;
}
.button.accordion-button{
  background: #F0F0F0!important;
}
.accordion-button:not(.collapsed){
  background: #F0F0F0!important;
}
.cardAcordionBody{
  background: #F0F0F0;
  padding: 12px;
}
.custom-header .accordion-button:not(.collapsed)::after{
  top: 20px;
  right: 65px;
  position: absolute;
}
.custom-header .accordion-button::after{
  top: 20px;
  right: 65px;
  position: absolute;
}
.dragLeftProduse{
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;
  width: 100%;
  overflow-y: auto;
}
.headerGenetica{
  border-bottom: dotted 2px #a7a4a4;
  display: flex;
}
.GeneticaBody{
  padding: 15px;
}
.GeneticaBody input{
  background-color: #f0f0f0;
}
.GeneticaBody label.form-check-label{
  color:#EF7D00;
}
.deleteFilesButton{
  color: #EF7D00;
  background-color: transparent;
  border: none;
}
.UploadFiles{
  background-color:#EF7D00;
  border: none;
  color: #ffffff;
  padding: 6px 20px;
  border-radius: 6px;
  font-weight: 600;
}
.noProductsDiv {
  margin: auto;
  min-height: 225px;
  padding-top: 15px;
}
.noProductsDiv p{
  text-align: center;
  width: 100%;
  margin: auto;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.formField{
 /* fontSize: "1.1rem", padding: "10px", height: "45px", */
  font-size: 16px;
  height: 45px;
  padding: 10px;
}
.cardProduct{
  background-color: white;
  position: relative;
  cursor: grab;
  padding: 12px;
  gap: 16px;
  width: 100%;
  border-bottom:2px solid #a1a1a16b;
  height: fit-content;
}
.cardProduct.collapsed .nameProduct {
  color: #a1a1a1!important;
}
.cardProductLeft:hover{
  background-color: #F0F0F0;
}
.cardProductCheckout{
  background-color: white;
  position: relative;
  cursor: grab;
  padding: 12px;
  gap: 16px;
  width: 100%;
  border-bottom:2px solid #a1a1a16b;
  height: fit-content;
}
.hideButton{
  background-color: transparent;
  margin-right: 20px;
  border: 1px solid #EF7D00;
}
.hideButton:hover{
  background-color: #00a651;
  cursor: pointer;
  border: 1px solid #00a651;
  color: #ffffff;
}
.hideButton svg {
  color: #EF7D00;
  width: 16px;
  height: 17px;
}
.hideButton svg:hover {
  color: #ffffff;
}
.showButton{
  background-color: transparent;
  border: 1px solid #a1a1a1;
  margin-bottom: 5px;
}
.showButton:hover{
  background-color: #00a651;
  cursor: pointer;
  border: 1px solid #00a651;
  color: #ffffff;
}
.showButton svg{
  color: #a1a1a1;
 ;
}
.showButton svg:hover{
  color: #F0F0F0;
 ;
}
.cardProductCheckout:hover{
  background-color: #F0F0F0;
}
.nameProduct{
  font-family: 'Raleway';
  color: #262626;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.anotherName{
  color: #666666;
}
.deleteAbosolute{
  top: 10px;
  right: 12px;
  position: absolute;
  display: none;
}
.svgGenetic{
  top: 18px;
  right: 15px;
  position: absolute;
}
.dragLeftProduse .boxPriceButtons{
  padding-top: 10px;
}
.persoaneH4{
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color:#666666 
}
.deleteButton {
  border: 1px solid #EF7D00;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EF7D00;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 10px 12px;
  gap: 12px;
  border-radius: 6px;
  height: 40px;
  width: 40px;
}
.deleteButtonText {
  border: 1px solid #EF7D00;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EF7D00;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 10px 12px;
  gap: 12px;
  border-radius: 6px;
  font-weight: 600;
}
.deleteButtonText:hover{
  background-color: #00a651;
  cursor: pointer;
  border: 1px solid #00a651;
}
.buttonFinalizare{
  background-color: #EF7D00;
  color: #fff;
  border: 1px solid #EF7D00;
  padding: 10px 15px;
  width: 100%;
  height: 50px;
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
.buttonFinalizare:hover{
  background-color: #00a651;
  cursor: pointer;
  border: 1px solid #00a651;
}
/* Hover state */
.deleteButton:hover {
  background-color: #EF7D00;
  color: #fff;
  border: none;
  border: 1px solid #EF7D00;
  cursor: pointer;
}
/* Transitions for smoother effect */
.deleteButton svg {
  transition: fill 0.3s ease;
}
.buttonsAddLess{
  background-color: #EF7D00;
  border: none;
 /* font-size: 20px;
  */
 /* padding: 4px 16px;
  */
 /* font-weight: 700;
  */
  margin: 0px 10px;
  border-radius: 50%;
 /* color: rgb(255, 255, 255);
  */
  width: 36px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.f20{
  font-size: 20px;
  color: #262626;
  font-weight: 700;
}
.preload_container_element {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preload_container_element svg{
  width: 65px;
  height: 65px;
}
.container_search_input{
  height: 60px;
}
input.search_form_multiorder {
  border: 2px solid #EF7D00;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  outline: none;
  padding: 8px;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}
.boxPriceButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxButtons {
  display: flex;
  justify-content: space-between;
}
.cartDiv{
  padding:10px;
  border-radius: 6px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  border: 2px solid #EF7D00 ;
}
.search_result_data{
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0px 4px 30px 0px #A6A6A640;
  -webkit-box-shadow: 0px 4px 30px 0px #A6A6A640;
  -moz-box-shadow: 0px 4px 30px 0px #A6A6A640;
  padding: 15px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search_result_data .product-item {
  border-bottom: 1px solid #A1A1A1;
  padding: 8px 15px 10px 15px;
}
.search_result_data .product-item h5 {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Raleway';
  line-height: 28px;
}
.search_result_data .product-item:hover{
  background-color: #F0F0F0;
  cursor: pointer;
}
.add_button_from_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}
.add_button_from_search p{
  color: #666666;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Raleway';
  margin-bottom: 0;
}
.preload_animation_search {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.opacity_cart_div{
  opacity: 0.5;
}
.no_prod_found_container {
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
}
.no_prod_found_container p{
  color: #666666;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Raleway';
  margin-bottom: 0;
}
.buttonCancel{
  border: none;
  background-color: transparent 
}
.buttonCancel:hover{
  background-color: transparent 
}
.sumar_list_products .item_sumar {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;
}
.sumar_list_products .item_sumar p{
  margin-bottom: 0;
}
.sumar_list_products .item_sumar p:nth-of-type(1){
  font-family: 'Raleway';
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #262626;
}
.sumar_list_products .item_sumar p:nth-of-type(2){
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.sumar_list_products .item_sumar p:nth-of-type(2) span{
  color: #EF7D00;
  margin-right: 5px;
}
.amount_save_money {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}
.amount_save_money p{
  margin-bottom: 0;
  font-family: 'Raleway';
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}
.total_cart_amount {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 8px;
  border-bottom: 1.5px solid #EF7D00;
  margin-bottom: 20px;
}
.total_cart_amount span,
.total_cart_amount p{
  margin-bottom: 0;
  font-family: 'Raleway';
  font-size: 22px;
  font-weight: 600;
  color: #262626;
}
.sumar_cart_page {
  border: 1px solid #A1A1A1;
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.container_sumar_analize{
  max-height: 300px;
  overflow: auto;
}
.website_checl_content_data .form-check, .row_checkout_radio .form-check{
  border-bottom: 0px solid #666666;
}
.row_checkout_radio .form-check:hover{
  cursor: pointer;
}
.checkput_sumar_comanda_container{
  border: 1px solid #A1A1A1;
  padding: 15px;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.checkput_sumar_comanda .header_sumar_checkout{
  border-bottom: 1.5px solid #A1A1A1;
  margin-bottom: 25px;
  padding-top: 10px;
}
.checkput_sumar_comanda .header_sumar_checkout p{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #7d7d7d;
}
.row_checkout_radio label span{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #262626;
}
.row_checkout_radio label span:hover{
  cursor: pointer;
}
.row_checkout_radio label span span.percentage_eliminat{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #EF7D00;
}
.payment_section_ash {
  padding-top: 15px;
}
.payment_section_ash h5{
  font-family: 'Raleway';
  font-size: 18px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 22px;
}
.content_firnal_checkout p, .website_checl_content_data label{
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #666666;
}
.card_component_style {
  margin-bottom: 30px;
  margin-top: 20px;
}
.titluModal{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #262626;
  font-weight: 600;
}
.textModal{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #262626;
  font-weight: 600;
  margin-left: 15px;
}
.buttonOrange{
  background-color: #EF7D00;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 6px;
  font-family: 'Raleway';
  height: 40px;
  font-weight: 600;
}
.closeButton{
  border: 1px solid #EF7D00;
  border-radius: 6px;
  background-color: #F0F0F0;
  padding: 5px 12px;
  color: #EF7D00;
  font-weight: 600;
}
.closeButton:hover{
  background-color: #00a651;
  color: #ffffff;
  border: none;
}
.closeButton svg:hover{
  background-color: #ffffff!important;
  color: #ffffff!important;
}
.buttonOrange:hover{
  background-color: #00a651;
 /* Orange background on hover */
}
.form-check-input:checked {
  background-color: #EF7D00;
  border-color: #EF7D00;
}
.modal-content {
  background-color: #F0F0F0;
}
.form-check{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.modal-body{
  border-top: 1px solid #262626;
}
.salePrice{
  color: #262626;
  font-family: 'Raleway';
  font-weight: 700;
  margin-bottom: 0;
  text-align: end;
}
.regularPrice{
  color: #a1a1a1!important;
  opacity: 1 !important;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
  text-align: end;
}
.produsCheckBox{
  background-color: white;
  padding: 10px 32px;
}
.grayDiv{
  background-color: #F0F0F0;
  padding: 12px;
}
.pPersonCheckout{
  font-size: 16px;
  color: #262626;
  font-family: 'Raleway';
  font-weight: 500;
}
.personName{
  font-size: 20px;
  font-weight: 600;
}
.colorOrange{
  color: #EF7D00;
  font-weight: 700;
}
.mobile_show_sumar{
  display: none;
}
@media only screen and (max-width: 767px) {
  .salePrice{
      font-size: 16px;
 }
  .regularPrice{
      font-size: 14px;
 }
  h1{
      font-size: 35px;
      line-height: 30px;
 }
  .mobileOrder2{
      order: 2;
      margin-top: 30px;
 }
  .mobileOrder1{
      order: 1;
 }
  .set_dimension_mobil {
      margin-top: 20px;
 }
  .desktop_summar_card{
      display: none;
 }
  .mobile_show_sumar{
      display: block;
      order: 3;
 }
}
@media only screen and (min-width: 768px) {
  h1{
      font-size: 40px;
      line-height: 64px;
 }
  .jm-sticky-top{
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
 }
  .salePrice{
      font-size: 18px;
 }
  .regularPrice{
      font-size: 16px;
 }
}
@media only screen and (min-width: 1024px) {
  h1{
      font-size: 40px;
      line-height: 64px;
 }
}
@media (max-width: 575.98px) {
  h1 {
      font-size: 25px;
 }
  .h4_font {
      font-size: 18px;
      line-height: 30px;
 }
  .boxPriceButtons {
      flex-wrap: wrap;
 }
  .boxPriceButtons .boxButtons{
      order: 2;
 }
  .price_data_redata {
      width: 100%;
      margin-bottom: 14px;
      display: flex;
      justify-content: end;
 }
  .price_data_redata .regularPrice{
      order: 2;
 }
  .price_data_redata .salePrice{
      order: 1;
      margin-right: 10px;
 }
  .titluModal {
      font-size: 16px;
 }
 .total_cart_amount span,
  .total_cart_amount p {
      font-size: 20px;
 }
  .buttonFinalizare {
      padding: 0px 0px;
      width: 100%;
      height: 50px;
      font-size: 17px;
 }
}
